import styled from 'styled-components'
import { colors } from '../../utils/colors'

export const ContainerLayout = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 1099px) {
    flex-direction: column;
  }
`

export const ChildrenContainer = styled.div`
  width: 100%;
  
  @media screen and (min-width: 1099px) {
    padding-left: 280px;
  }
`

export const HeaderContainer = styled.div`
  height: 48px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.main};
`

export const DrawerContainer = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 1099px) {
    display: inline;
  }
`
export const SidebarContainer = styled.div`
  display: none;
  @media screen and (min-width: 1099px) {
    display: inline;
  }
`

export const LogoImg = styled.img`
  /* height: 80%; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
`
