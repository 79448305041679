import axios from 'axios'
import {enqueueSnackbar} from "notistack";

const apiUrl = process.env.REACT_APP_API_URL;
export const axiosApi = axios.create({
  baseURL: apiUrl
});
export const accessToken = () => localStorage.getItem("token");

const api = {
  get: (url: string, config = {}) => axiosApi.get(url, { headers: { ...config, 'Authorization': accessToken() }}),
  put: (url: string, data = {}, config = {}) => axiosApi.put(url, data, { headers: { ...config, 'Authorization': accessToken() }}),
  post: (url: string, data = {}, config = {}) => axiosApi.post(url, data, { headers: { ...config, 'Authorization': accessToken() }}),
  delete: (url: string, config = {}) => axiosApi.delete(url, { headers: { ...config, 'Authorization': accessToken() }}),
  patch: (url: string, data = {}, config = {}) => axiosApi.patch(url, data, { headers: { ...config, 'Authorization': accessToken() }}),
}

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status){
      case 400:
        enqueueSnackbar(error.response.data.message, {variant: "warning"})
        break;
      case 500:
        if(error.response.data.message.includes("JWT")){
          if(!window.location.href.includes("/login")){
            window.location.href = "/login"
          }
        }else {
          enqueueSnackbar(error.response.data.message, {variant: "error"})
        }
        break;
      case 401:
        if(!window.location.href.includes("/login")){
          window.location.href = "/login"
        }
        break;
      case 403:
        window.location.href = "/login"
        break;
    }

    return Promise.reject(error);
  }
);
export default api
