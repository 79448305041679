import api from './api'

export async function fetchUsers(page, rowsPerPage) {
  try {
    return await api.get(`/usuarios?page=${page}&size=${rowsPerPage}`)
  } catch (error) {
    console.error(error)
  }
}

export async function AllUsers() {
  const response = await api.get(`/usuarios`)
  return response?.data
}

export async function deleteUser(id: number) {
  try {
    return await api.delete(`/usuarios/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findUser(id: number) {
  try {
    return await api.get(`/usuarios/${id}`)
  } catch (error) {
    console.error(error)
  }
}
