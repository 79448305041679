import api from './api'

export async function AllMeasurement() {
  const response = await api.get('/unidades')
  return response?.data
}

export async function fetchMeasurements(page, rowsPerPage, filter?: string) {
  try {
    return await api.get(`/unidades?page=${page}&size=${rowsPerPage}&filter=${filter}`)
  } catch (error) {
    console.error(error)
  }
}

export async function deleteMeasurement(id: number) {
  try {
    return await api.delete(`/unidades/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findMeasurements(id: number) {
  try {
    return await api.get(`/unidades/${id}`)
  } catch (error) {
    console.error(error)
  }
}
