import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

interface IConfirmationModal {
  title: string
  message: string
  submessage?: string
  open: boolean
  onAgree: () => void
  onDisagree: () => void
}

export default function ConfirmationModal(options: IConfirmationModal) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleAgreed = () => {
    options.onAgree()
  }

  const handleDisagre = () => {
    options?.onDisagree()
  }

  return (
    <Dialog fullScreen={fullScreen} open={options.open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{options.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{options.message}</DialogContentText>
        <DialogContentText sx={{fontSize: '14px', color: '#F00'}}>{options.submessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDisagre}>
          Cancelar
        </Button>
        <Button onClick={handleAgreed} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
