import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Card, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import api from '../../../services/api'
import * as S from './styles'

import { enqueueSnackbar } from 'notistack'
import BackButton from '../../../components/BackButton'
import ComplementInput from '../../../components/ComplementInput'
import { fetchEnvironments as fetchCategory } from '../../../services/AmbienteService'
import { needAdmin } from '../../../services/AuthService'
import { findServiceType } from '../../../services/TipoDeServicoService'

const schemaAddService = yup.object().shape({
  serviceCategory: yup.string().required('Selecione o ambiente do serviço'),
  name: yup.string().required('Informe o nome do serviço')
})

export default function FormServiceType() {
  needAdmin()
  const [selectedItem, setSelectedItem] = useState('')
  const [allCategory, setAllCategory] = useState([])
  const navigate = useNavigate()
  const params = useParams()
  const refBtn = useRef(null)

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schemaAddService)
  })

  const handleCreateService = async data => {
    const createServiceRequest = {
      ambiente: {
        id: data.serviceCategory
      },
      nome: data.name
    }

    try {
      if (params?.id) {
        await api.put(`/tipos-de-servicos/${params.id}`, createServiceRequest)
        enqueueSnackbar('Serviço editado com sucesso', { variant: 'success' })
      } else {
        await api.post('/tipos-de-servicos', createServiceRequest)
        enqueueSnackbar('Serviço inserido com sucesso', { variant: 'success' })
      }
      navigate('/servicos')
    } catch (e) {
      console.error(e)
    }
  }

  const handleSelectItem = event => {
    setSelectedItem(event.target.value)
    setValue('serviceCategory', event.target.value)
  }

  useEffect(() => {
    ;(async () => {
      const allCategorias = await fetchCategory(0, 1000, '')
      setAllCategory(allCategorias.data.content)
    })()
    if (params?.id) {
      ;(async () => {
        const response = await findServiceType(parseInt(params.id))
        setValue('name', response.data.nome)
        setValue('serviceCategory', response.data.ambiente.id)
        setSelectedItem(response.data.ambiente.id)
      })()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', (e: any) => {
      if (!e?.repeat) {
        if (e?.keyCode === 13 || e?.keyCode === 120) {
          refBtn?.current?.click()
        }
      }
    })

    return document.removeEventListener('keydown', e => {})
  }, [])

  return (
    <S.FullPage>
      <BackButton onClick={() => navigate(`/servicos`)}>Voltar</BackButton>
      <S.TitlePage>{params?.id ? `Editar serviço` : `Adicionar serviço`}</S.TitlePage>
      <Card elevation={3}>
        <S.ContainerFieldset>
          <S.InputRow rowsSize={[0.96, 2]}>
            <Controller
              control={control}
              name="serviceCategory"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ComplementInput label="Ambiente" required error={error?.message}>
                  <Select value={selectedItem} onChange={(e: any) => onChange(e.target.value, handleSelectItem(e))}>
                    {allCategory.map(item => (
                      <MenuItem value={item.id}>{item.nome}</MenuItem>
                    ))}
                  </Select>
                </ComplementInput>
              )}
            />
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ComplementInput label="Nome" required error={error?.message}>
                  <TextField onChange={(e: any) => onChange(e.target.value)} value={value} />
                </ComplementInput>
              )}
            />
          </S.InputRow>
          <Button
            sx={{ width: 260, height: 46, mt: 1 }}
            onClick={handleSubmit(handleCreateService)}
            variant="contained"
            ref={refBtn}
          >
            Salvar
          </Button>
        </S.ContainerFieldset>
      </Card>
    </S.FullPage>
  )
}
