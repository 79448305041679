import api from './api'

export async function AllMaterial() {
  const response = await api.get('/materials')
  return response?.data
}

export async function fetchMaterials(page, rowsPerPage, filter?: string) {
  try {
    return await api.get(`/materials?page=${page}&size=${rowsPerPage}&filter=${filter}`)
  } catch (error) {
    console.error(error)
  }
}

export async function deleteMaterial(id: number) {
  try {
    return await api.delete(`/materials/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findMaterial(id: number) {
  try {
    return await api.get(`/materials/${id}`)
  } catch (error) {
    console.error(error)
  }
}