import api from './api'

export async function AllServiceType() {
  const response = await api.get('/tipos-de-servicos')
  return response?.data
}

export async function fetchServiceTypes(page, rowsPerPage, filter?: string) {
  try {
    return await api.get(`/tipos-de-servicos?page=${page}&size=${rowsPerPage}&filter=${filter}`)
  } catch (error) {
    console.error(error)
  }
}

export async function deleteServiceType(id: number) {
  try {
    return await api.delete(`/tipos-de-servicos/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findServiceType(id: number) {
  try {
    return await api.get(`/tipos-de-servicos/${id}`)
  } catch (error) {
    console.error(error)
  }
}