import React, { CSSProperties } from 'react'
import * as S from './styles'

import { colors } from '../../utils/colors'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface IBackButton {
  children: any
  style?: CSSProperties
  onClick?: any
}

function BackButton({ children, style, onClick }: IBackButton) {
  return (
    <S.Button style={style} onClick={onClick}>
      <ArrowBackIcon sx={{ fontSize: 20, color: colors.second }} />
      {children}
    </S.Button>
  )
}

export default BackButton
