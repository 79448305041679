import api from './api'

export async function fetchCustomers(page, rowsPerPage, filter?: string) {
  try {
    return await api.get(`/clientes?filter=${filter}&page=${page}&size=${rowsPerPage}`)
  } catch (error) {
    console.error(error)
  }
}

export async function AllCustomers() {
  const response = await api.get('/clientes')
  return response?.data
}

export async function deleteCustomer(id: number) {
  try {
    return await api.delete(`/clientes/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findCustomers(id: number) {
  try {
    const response = await api.get(`/clientes/${id}`)
    return response?.data
  } catch (error) {
    console.error(error)
  }
}

export async function fetchAcquisition() {
  const response = await api.get('/clientes/all-tipos-indicacao')
  return response?.data
}

export async function fetchGender() {
  const response = await api.get('/clientes/all-generos')
  return response?.data
}