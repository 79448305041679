import styled from 'styled-components'
import { colors } from '../../utils/colors'

export const ContainerComplementInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.p`
  display: inline-flex;
  align-items: flex-start;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  color: ${colors.second};

  @media screen and (max-width: 1099px) {
    font-size: 17px;
  }
`

export const ErrorText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.red};  

  @media screen and (max-width: 1099px) {
    font-size: 16px;
  }
`

export const RequiredInput = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.red};
`
