import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Card,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ComplementInput from '../../components/ComplementInput'
import ConfirmationModal from '../../components/DialogModal/ConfirmationModal'
import { TablePaginationActions } from '../../components/Table/Pagination'
import { deleteEnvironment, fetchEnvironments } from '../../services/AmbienteService'
import * as S from './styles'

function Envinronments() {
  function getStorage(name: string) {
    return JSON?.parse(localStorage.getItem('SERVICES'))?.[name]
  }
  const [idToDelete, setIdToDelete] = useState()
  const [rows, setRows] = useState(getStorage('rows') || [])
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
  const [service, setService] = useState(getStorage('service') || '')
  const refBtn = useRef(null)

  const params = useLocation()
  const navigate = useNavigate()

  //Pagination
  const [rowsPerPage, setRowsPerPage] = useState(getStorage('rowsPerPage') || 10)
  const [page, setPage] = useState(getStorage('page') || 0)
  const [totalRows, setTotalRows] = useState(getStorage('totalRows') || 0)

  const navigateNewEnvironment = () => {
    navigate(`/ambientes/new`)
  }

  const navigateToEdit = (id: number) => {
    navigate(`/ambientes/edit/${id}`)
  }

  const handleServiceExclusion = async () => {
    await deleteEnvironment(idToDelete)
    setIsOpenConfirmation(false)
    updateTable()
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  function getCategoriaDescription(categoria: string) {
    switch (categoria) {
      case 'ADULTO':
        return 'Adulto'
      case 'PEDIATRA':
        return 'Pediatra'
    }
  }

  const updateTable = async () => {
    const response = await fetchEnvironments(page, rowsPerPage, service)
    if (response.data.content <= rowsPerPage * page && page > 0) {
      setPage(page - 1)
    }
    setRows(response.data.content)
    setTotalRows(response.data.totalElements)
    const storage = {
      rows: response.data.content,
      page,
      rowsPerPage,
      service,
      totalRows: response.data.totalElements
    }
    localStorage.setItem('SERVICES', JSON.stringify(storage))
  }

  useEffect(() => {
    updateTable()
  }, [page, rowsPerPage])

  useEffect(() => {
    document.addEventListener('keydown', (e: any) => {
      if (e?.keyCode === 13 && !e?.repeat) {
        refBtn.current.click()
      }
    })

    return document.removeEventListener('keydown', e => {})
  }, [])

  return (
    <S.FullPage>
      <S.TitlePage>Ambientes</S.TitlePage>
      <Button sx={{ width: 260, height: 46 }} variant="contained" onClick={navigateNewEnvironment}>
        Registrar
      </Button>
      <Card elevation={3}>
        <TableRow
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '32px', px: '16px', mt: '8px' }}
        >
          <ComplementInput label="Ambiente">
            <TextField value={service} onChange={e => setService(e.target.value)} />
          </ComplementInput>
          <Button
            ref={refBtn}
            sx={{ width: 260, height: 56, marginTop: '12px' }}
            variant="contained"
            onClick={updateTable}
          >
            <SearchIcon fontSize="large" />
          </Button>
        </TableRow>
        <Divider sx={{ width: '100%' }} />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '30%' }}>Nome</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell sx={{ minWidth: '64px', padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 &&
                rows?.map(row => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ width: '30%' }}>{row?.nome}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ minWidth: '64px', padding: 0 }}>
                      <Tooltip title="Editar">
                        <IconButton sx={{ padding: '4px' }} onClick={() => navigateToEdit(row?.id)}>
                          <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Deletar">
                        <IconButton
                          sx={{ padding: '4px' }}
                          onClick={() => {
                            setIdToDelete(row.id)
                            setIsOpenConfirmation(true)
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} sx={{ height: '56px', textAlign: 'center' }}>
                    Nenhum serviço adicionado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={7}
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
      <ConfirmationModal
        title={'Atenção!'}
        message={'Deseja realmente excluir este serviço?'}
        submessage="Essa ação não poderá ser desfeita."
        open={isOpenConfirmation}
        onAgree={handleServiceExclusion}
        onDisagree={() => setIsOpenConfirmation(false)}
      />
    </S.FullPage>
  )
}

export default Envinronments
