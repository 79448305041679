import api from './api'

export async function AllEnvironment() {
  const response = await api.get('/ambientes')
  return response?.data
}

export async function fetchEnvironments(page, rowsPerPage, filter?: string) {
  try {
    return await api.get(`/ambientes?page=${page}&size=${rowsPerPage}&filter=${filter}`)
  } catch (error) {
    console.error(error)
  }
}

export async function deleteEnvironment(id: number) {
  try {
    return await api.delete(`/ambientes/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function findEnvironments(id: number) {
  try {
    return await api.get(`/ambientes/${id}`)
  } catch (error) {
    console.error(error)
  }
}

export async function fetchAll() {
  const response = await api.get('/ambientes/all')
  return response?.data
}
